<script setup>
import { form, formV$ } from "~/validations/linkFormRules";
import { useLinkFormStore } from "~/store/useLinkFormStore.js";

import { storeToRefs } from "pinia";

const { isSubmitted, responseError, clearResponseError } = storeToRefs(
  useLinkFormStore()
);

const expirationRedirectError = computed(() => {
  return responseError.value?.details?.form_key === "expiration_redirect_url"
    ? responseError.value?.message
    : undefined;
});
</script>

<template>
  <div class="select-none">
    <div class="my-4"></div>

    <!-- responseError -->
    <Message
      v-if="responseError?.details?.tab_key === 'schedule-lnks' && responseError?.message"
      severity="error"
      :sticky="true"
      :closable="false"
      >{{ responseError?.message }}</Message
    >

    <!-- Date Time Range Field -->
    <CustomFieldDatetimeRange :DateTimeRangeField="formV$.dateTimeRange" />

    <template v-if="form.dateTimeRange?.length >= 2 && form.dateTimeRange[1] != null">
      <!-- Expiration Redirect Field -->
      <CustomFieldText
        v-if="!formV$.autoDltOnExpire.$model"
        :Field="formV$.expirationRedirect"
        :isSubmitted="isSubmitted"
        :label="'Redirect on expire URL'"
        :inputId="'redirect-on-expire-input-field'"
        :placeholder="'Type or paste a link'"
        :responseError="expirationRedirectError"
        @clearResponseError="clearResponseError"
      />

      <!-- Auto Delete On Expire Field -->
      <CustomFieldSwitch
        :Field="formV$.autoDltOnExpire"
        :isSubmitted="isSubmitted"
        :label="'Auto Delete on Expire'"
        :switchId="'auto-dlt-on-expire-input'"
      />
    </template>
  </div>
</template>

<style></style>
